import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { ensurePublicURL } from "../general"
import Fade from "react-reveal/Fade"

export const SolutionFeatures = ({ features }) => {
  return (
    <Container className="solution-features-container pt-5" fluid>
      <h2 className="section-header pt-5 ">
        SOLUTION <span>FEATURES</span>
      </h2>
      {features.map((feature, i) => (
        <FeatureGroup feature={feature} key={i} alt={i % 2 === 0} />
      ))}

      <div style={{ height: "220px" }} />
    </Container>
  )
}
const FeatureGroup = ({ alt, feature }) => {
  const order = alt ? 12 : 1
  const otherOrder = alt ? 1 : 12
  const featureImage = ensurePublicURL(feature.feature_image)
  return (
    <>
      <div style={{ height: "120px" }} />
      <Container fluid className="feature-bullet-container">
        <Row>
          <Col
            className="elevator-col"
            lg={{ order: order, span: 6, offset: alt ? 1 : 0 }}
          >
            <Fade >
              <div>
                <h3 className="text-left" style={{ fontSize: "3em" }}>
                  {feature.heading}
                </h3>
                <Row>
                  <Col lg={6} xs={12}>
                    <Fade>
                      <Row className="pt-5">
                        <Col xs={1} lg={2}>
                          <div className="plus"> &#43;</div>
                        </Col>
                        <Col xs={10} lg={10}>
                          <h5 className="feature-header text-left">
                            {feature.feature_title_1}
                          </h5>
                          <p className="text-left">
                            {feature.feature_excerpt_1}
                          </p>
                        </Col>
                      </Row>
                    </Fade>
                  </Col>
                  <Col lg={6} xs={12}>
                    <Row className="pt-5">
                      <Col xs={1} lg={2}>
                        <div className="plus"> &#43;</div>
                      </Col>
                      <Col xs={10} lg={10}>
                        <h5 className="feature-header text-left">
                          {feature.feature_title_2}
                        </h5>
                        <p className="text-left">{feature.feature_excerpt_2}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Fade>
          </Col>
          <Col lg={{ order: otherOrder, span: 5, offset: alt ? 0 : 1 }}>
            <Fade left={alt} right={!alt}>
              <Image
                className="feature-image"
                fluid
                src={featureImage.publicURL}
              />
            </Fade>
          </Col>
        </Row>
      </Container>
      <div className="mobile-hide" style={{ height: "120px" }} />
    </>
  )
}
