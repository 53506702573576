import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { DemoModalContext } from "../demo-modal-context"
import Fade from "react-reveal/Fade"

export const SolutionBenefits = ({ benefits, benefitBackground }) => {
  return (
    <Container fluid className="solution-benefits-container p-0">
      <div className="mobile-hide" style={{ height: "80px" }} />
      <Container
        fluid
        className="benefits-row-container"
        style={{ backgroundImage: `url(${benefitBackground})` }}
      >
        <div className="benefit-shift-up">
          <Fade bottom>
            <Row>
              <Col className="benefit-header-col" lg={4} xs={12}>
                <div className="benefit-header">
                  <span>[</span>BENEFITS<span>]</span>
                </div>
              </Col>
            </Row>
            <Row>
              {benefits.map((b, i) => {
                return <Benefit key={i} benefit={b} index={i} />
              })}
            </Row>
            <Row className="mt-5">
              <Col
                lg={{ span: 8, offset: 4 }}
                className="benefit-cta-container"
              >
                <Row>
                  <Col xs={0} lg={1}>
                    <div className="benefit-border" />
                  </Col>
                  <Col xs={12} lg={11}>
                    <h5 className="text-left pt-3 pb-3">
                      IT'S EASY TO GET STARTED
                    </h5>
                    <Row>
                      <Col lg={7} xs={12}>
                        <p className="text-left">
                          Reach out and request your free, personalized demo
                          today.
                        </p>
                      </Col>
                      <Col lg={{ span: 4, offset: 1 }} xs={12}>
                        <DemoModalContext.Consumer>
                          {({ showModal, toggleModal }) => (
                            <div
                              className="cta-button"
                              onClick={() => toggleModal()}
                            >
                              REQUEST A DEMO
                              <FontAwesomeIcon
                                className="ml-3"
                                icon={faArrowRight}
                              />
                            </div>
                          )}
                        </DemoModalContext.Consumer>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Fade>
        </div>
      </Container>
    </Container>
  )
}
const Benefit = ({ benefit, index }) => {
  return (
    <Col className="benefit-card " lg={4} xs={12}>
      <Row>
        <Col xs={1} lg={1} className="p-0">
          <div className="benefit-number">{index + 1}</div>
        </Col>
        <Col xs={11} lg={11}>
          <h6 className="text-left">{benefit.heading}</h6>
          <p className="text-left">{benefit.excerpt}</p>
        </Col>
      </Row>
    </Col>
  )
}
