import React from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import { Footer } from "../components/landing/_Footer"
import { SolutionsHeader } from "../components/solutions/SolutionsHeader"
import { QuoteSection } from "../components/solutions/QuoteSection"
import { ElevatorFeatures } from "../components/solutions/ElevatorFeatures"
import { SolutionFeatures } from "../components/solutions/SolutionFeatures"
import { SolutionBenefits } from "../components/solutions/SolutionBenefits"
import { ensurePublicURL } from "../components/general"

export const query = graphql`
  query SolutionQuery($id: Int!) {
    strapiSolution(strapiId: { eq: $id }) {
      id
      cta
      description
      client_quote {
        excerpt
        heading
        quote
        quote_name
        quote_position
        client {
          logo {
            publicURL
          }
        }
      }
      elevator_features {
        excerpt
        heading
        icon {
          publicURL
        }
      }
      image_right {
        publicURL
      }
      image_left {
        publicURL
      }
      logo {
        publicURL
      }
      image_center {
        publicURL
      }
      benefits_background {
        publicURL
      }
      page_class
      solution_benefits {
        excerpt
        heading
      }
      solution_features {
        feature_excerpt_1
        feature_excerpt_2
        feature_title_1
        feature_title_2
        heading
        feature_image {
          publicURL
        }
      }
      solution_header {
        header
        excerpt
        background_image {
          publicURL
        }
      }
      solution_name
      strapiId
    }
  }
`

const IndexPage = ({ data }) => {
  const solutionData = data.strapiSolution
  solutionData.benefitBackground = ensurePublicURL(
    solutionData.benefits_background
  )

  return (
    <Layout
      title={
        "Gravitate " +
        solutionData.solution_name +
        " - " +
        solutionData.solution_header.header.replace("#", "").replace(/\*/g, "")
      }
      image={
        ensurePublicURL(solutionData.solution_header.background_image).publicURL
      }
      description={solutionData.solution_header.excerpt}
      colorClass={solutionData.page_class}
    >
      <div className={solutionData.page_class}>
        <SolutionsHeader data={solutionData} />
        <Container className="solutions-body-container p-0" fluid>
          <QuoteSection clientQuote={solutionData.client_quote} />
          <ElevatorFeatures features={solutionData.elevator_features} />
          <SolutionFeatures features={solutionData.solution_features} />
          <SolutionBenefits
            benefits={solutionData.solution_benefits}
            benefitBackground={solutionData.benefitBackground.publicURL}
          />
          <Footer />
        </Container>
      </div>
    </Layout>
  )
}

export default IndexPage
