import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { ensurePublicURL } from "../general"
import Fade from "react-reveal/Fade"

export const ElevatorFeatures = ({ features }) => {
  return (
    <Container fluid className="elevator-features-container">
      <Fade>
        <div style={{ height: "80px" }} />
        <Row>
          {features.map((e, i) => {
            let colSpan = 4
            if (features.length % 3 === 1) {
              if (i === features.length - 1) {
                colSpan = 12
              }
            }
            if (features.length % 3 === 2) {
              if (i >= features.length - 2) {
                colSpan = 6
              }
            }
            return <Perk perk={e} key={i} index={i} colSpan={colSpan} />
          })}
        </Row>

        <div style={{ height: "80px" }} />
      </Fade>
    </Container>
  )
}
const Perk = ({ perk, colSpan }) => {
  const perkIcon = ensurePublicURL(perk.icon)
  return (
    <Col lg={colSpan} xs={12}>
      <div className="pt-5 ">
        <div>
          <Image className="mt-3" src={perkIcon.publicURL} thumbnail />
        </div>
        <div>
          <h6 className="pt-3 perk-header">{perk.heading}</h6>
        </div>
        <div>
          <p className="pt-3 perk-excerpt">{perk.excerpt}</p>
        </div>
      </div>
    </Col>
  )
}
