import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import { ensurePublicURL } from "../general"
export const QuoteSection = ({ clientQuote }) => {
  return (
    <Container className="quote-section" fluid>
      <Row>
        <Col lg={7} xs={12}>
          <h3 className="text-left pb-3">{clientQuote.heading}</h3>
          <p className="text-left" style={{ maxWidth: 700 }}>
            {clientQuote.excerpt}
          </p>
        </Col>
        <Col lg={5} xs={12}>
          <div className="quote text-left">“</div>
          <p className="text-quote text-left">{clientQuote.quote}</p>
          <Row>
            <Col lg={6} xs={12}>
              {clientQuote.quote_name && (
                <h5 className="pt-3 text-left">{clientQuote.quote_name}</h5>
              )}
              {clientQuote.quote_position && (
                <h6 style={{ fontSize: "1em" }} className="text-left">
                  {clientQuote.quote_position}
                </h6>
              )}
            </Col>
            <Col lg={6} xs={12}>
              {clientQuote.client && (
                <Image
                  className="client-quote-logo"
                  fluid
                  src={ensurePublicURL(clientQuote.client.logo).publicURL}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
