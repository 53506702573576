import React from "react"
import { Container, Image } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import { ensurePublicURL } from "../general"


export const SolutionsHeader = ({ data }) => {
  const backgroundImage = ensurePublicURL(data.solution_header.background_image)
  const screenRight = ensurePublicURL(data.image_right)
  const screenLeft = ensurePublicURL(data.image_left)
  const screenCenter = ensurePublicURL(data.image_center)
  return (
    <Container
      fluid
      style={{
        backgroundImage: `url(${backgroundImage.publicURL},)`,
        backgroundSize: "cover",
      }}
      className="solution-landing-container p-0"
    >
      <div className="solution-overlay">
        <Image src={data.logo.publicURL} className="solution-landing-logo" />

        <ReactMarkdown source={data.solution_header.header} />

        <p>{data.solution_header.excerpt}</p>
        <div className="screens">
          <Image fluid className="screen" src={screenRight.publicURL} />
          <Image fluid className="screen" src={screenCenter.publicURL} />
          <Image fluid className="screen" src={screenLeft.publicURL} />
        </div>
      </div>
    </Container>
  )
}
